.phone__icon {
	margin-right: 1rem;
	position: relative;
	top: 0;
	width: 2rem;
	margin-top: 0;
	margin-bottom: 0;
	display: inline-block; }
.download__icon {
	position: relative;
	margin-right: 1rem;
	top: 3px;
	width: 3rem; }
