form {
	max-width: 100%;
	overflow-x: hidden;
	select {
		width: 100%;
		padding: 1rem;
		background: white;
		border-radius: 10rem;
		border: 2px solid $hellgrau;
		text-align: center;
		color: $black; }
	.form__header {
		margin-bottom: 2rem;
		text-align: center;
		.form--heading {
			color: $rot;
			margin-bottom: 0; }
		h4 {
			color: $rot;
			font-size: 1.6rem;
			font-weight: 600;
			span {
				font: inherit;
				color: inherit; } } }
	.form__content {
		.question {
			display: none;
			&.active {
				display: block; } }
		.showstopper {
			display: none;
			&.active {
				display: block; } }

		strong {
			margin-bottom: 0.5rem;
			display: block;
			font-size: 1.8rem;
			// font-family: $value
			text-align: center;
			line-height: 1.2; }
		* {
			font-size: 1.6rem; }
		.btn-outline {
			display: block;
			width: 100%;
			padding: 1rem 2rem;
			color: $rot;
			text-align: center;
			border: 2px solid $hellgrau;
			border-radius: 10rem;
			//margin-bottom: 1rem
			transition: all 200ms ease;
			cursor: pointer;
			font-size: 1.8rem;
			&:hover {
				// background: $rot
				// color: white
				border-color: $rot; } }
		input[type="radio"] {
			display: none;
			&:checked {
				&~.btn-outline {
					border-color: $rot; } } }
		input[type="text"], input[type="number"],input[type="email"] {
			border: 2px solid $hellgrau;
			padding: 1rem 2rem;
			width: 100%;
			//margin-bottom: 1rem
			border-radius: 3rem;
			font-size: 1.8rem;
			text-align: center; }
		.main__button {
			font-size: 1.8rem;
			margin-bottom: 2rem;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			.large {
				min-width: 100%;
				font-size: 1.8rem;
				font-weight: 600; }
			img {
				margin-left: 1rem; } }
		.inputGroup {
			display: flex;
			align-items: center;
			position: relative;
			flex-wrap: wrap;
			margin-bottom: 1rem;
			.toggle {
				margin-right: 1rem; }
			#check, #checksession {
				display: none;
				&:checked {
					&~label {
						.toggle {
							&:after {
								left: 2.2rem;
								background: $rot; } } } } }
			&.success {
				input {
					border-color: $success;
					color: $success; }
				&:before {
					content: "";
					position: absolute;
					top: 0;
					right: 0;
					width: 4rem;
					height: calc(100% - 4px);
					border-radius: 0 3rem 3rem 0;
					background: url('../img/icn-checkmark-success.svg') center no-repeat;
					background-size: 2rem;
					background-color: white;
					border: 2px solid $success;
					border-left: none; } } }
		.toggle_check {
			display: flex;
			align-items: center;
			span {
				font-size: 1.4rem;
				line-height: 1.4; } } } }

.alert {
	font-weight: 700;
	color: $rot;
	margin: 1rem 0;
	text-align: center;
	display: block;
	min-width: 100%; }

#bullets {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 380px;
	margin: 3rem auto 1rem;
	// padding: 0 2rem 3rem
	position: relative;
	overflow: hidden;
	&:before {
		content: "";
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		width: 100%;
		height: 2px;
		background: $grau;
		z-index: -1; }
	.bullet {
		display: block;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		background: #fff;
		border: 2px solid $grau;
		position: relative;
		transition: all 200ms ease;

		&:before {
			transition: all 200ms ease;
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%) scale(0);
			width: 75%;
			height: 75%;
			border-radius: 50%;
			background: $grau;
			opacity: 0; }
		&.activated {
			border: 2px solid $grau;

			&:before {
				opacity: 1;
				background: $grau;
				transform: translate(-50%,-50%) scale(1); }
			&:after {
				background: $grau; }
			&:not(.disabled) {
				cursor: pointer; } }
		&.active {
			border: 2px solid $rot;
			&:before {
				content: "";
				opacity: 1;
				background: $rot;
				transform: translate(-50%,-50%) scale(1); }
			&:after {
				content: "";
				background: $rot;
				position: absolute;
				z-index: -1;
				height: 2px;
				width: 100vw;
				top: 50%;
				left: 0;
				transform: translate(-100vw, -50%); } }
		&:first-child {
			&:after {
				display: none; } } } }

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance:textfield {} }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	box-shadow: 0 0 0px 1000px #fff inset;
	transition: background-color 5000s ease-in-out 0s; }



input::-webkit-outer-spin-button,input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0; }

#anrede-wrapper {
	display: flex;
	align-items: center;
	margin-bottom: 2rem;
	justify-content: center;
	.inputGroup {
		margin: 0;
		&:first-child {
			margin-right: 2rem; }

		label {
			position: relative;
			padding-left: 3rem;
			cursor: pointer;
			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 2rem;
				height: 2rem;
				border-radius: 50%;
				border: 2px solid $hellgrau; } }
		input:checked {
			&~label {
				&:before {
					border-color: $rot;
					background: radial-gradient($rot 50%, white 55%); } } } } }
