.testimonial {
	display: flex;
	align-items: center;
	img {
		width: 4.5rem;
		height: 4.5rem;
		margin-right: 1rem; }
	.testimonial--info {
		* {
			font-family: $univers;
			font-size: 1.2rem;
			color: $grau; } } }
