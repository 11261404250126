#version--redaktionell {
	background: white;
	*:not(.sansserif) {
		font-family: 'Roboto Slab', serif; }
	h1, h2 {
		font-size: 2.2rem;
		color: $black;
		@media (min-width: $mobile) {
			font-size: 4rem; } }

	header {
		background: white;
		width: 100%;
		border-bottom: 1px solid $rot;
		nav {
			width: 100%;
			max-width: 80rem;
			margin: 0 auto; }
		.header--hinweis {
			background: $rot;
			text-align: center;
			padding: 1rem;
			font-size: 1.2rem;
			* {
				color: white; }
			a {
				text-decoration: none; } } }
	.main--section {
		width: 100%;
		max-width: 80rem;
		padding: 2rem;
		margin: 0 auto;
		small {
			font-size: 1.2rem;
			font-family: univers, sans-serif; }
		hr {
			border: none;
			width: 10rem;
			background: $grau;
			height: 1px;
			margin: 1rem 0; }

		.testimonial {
			display: flex;
			align-items: center;
			margin: 2rem 0;
			img {
				width: 4.5rem;
				height: 4.5rem;
				border-radius: 50%;
				margin-right: 1rem; }
			.testimonial--info {
				strong, p {
					font-size: 1.2rem;
					color: $grau;
					margin: 0; } } }
		.kk-bild {
			background-size: cover;
			width: 100%;
			height: 50vw;
			max-height: 40rem;
			cursor: pointer;
			margin: 2rem 0;
			background-position: center; }

		.krankenkassenbild {
			background-image: url('/assets/img/krankenkasse/header.jpg'); }
		.hoerenbild {
			background-image: url('/assets/img/krankenkasse/hoeren.jpg');
			margin-top: 2rem; }
		.hoergeraetebild {
			background-image: url('/assets/img/krankenkasse/hoergeraet.jpg');
			background-position: top center; } }


	.infobox {
		border: 1px solid $grau;
		.top--content {
			padding: 2rem;
			background: $hellgrau;
			.checklist {
				li {
					font-weight: normal; } } }
		.bottom--content {
			background: $rot;
			padding: 2rem;
			* {
				color: white; }
			.main__button {
				border: 1px solid white;
				margin-top: 1rem; } } }

	#lightbox {
		.lightbox {
			padding: 1rem;
			max-width: 100%; } }

	#loader {
		background: white!important;
		h3 {
			text-align: center; } }

	footer {
		text-align: center;
		padding: 2rem 0;
		border-top: 1px solid $rot;
		margin-top: 2rem;
		display: flex;
		justify-content: center;
		.legal--links {
			text-align: center;
			margin: 0 auto;
			display: block;
			a {
				display: block;
				margin-bottom: 1rem; }
			@media (min-width: $mobile-s) {
				display: flex; } } } }

#pkv-page {
	#frage1 {
		label {
 } } }			// font-size: 1.4rem
.kantone {
	margin: 2rem 0;
	@media (min-width: $mobile-s) {
		display: flex;
		flex-wrap: wrap; }
	.radio--group {
		display: block;
		margin-bottom: 1rem;
		width: 100%;
		label {
			cursor: pointer;
			display: flex;
			align-items: center;
			font-size: 1.6rem;
			border: 1px solid $grau;
			border-radius: 10rem;
			padding: 0.5rem 1rem;
			color: $rot;
			transition: all 300ms ease;
			height: 5rem;
			justify-content: center;
			text-align: center;
			.label__content {
				display: flex;
				align-items: center;
				width: 13rem; }
			.kk--icon {
				width: 3rem;
				height: 3.6rem;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
				margin-right: 1rem; }
			&.label-noicon {
				text-align: center;
				justify-content: center;
				height: 5rem;
				background: $rot;
				color: white;
				border-color: $rot;
 }				// padding: 1.5rem 1rem
			&:hover {
				background: $hellgrau;
				color: $rot; } }
		input {
			display: none;
			&:checked {
				&~label {
					background: white!important;
					color: $rot!important;
					border-color: $rot!important; } } }
		flex: 1 0 45%;
		&:nth-child(odd) {
			margin-right: 1rem; }
		&:last-child {
			margin-right: 0; } } }
