#glueckliche_kunden {
	background: $rot;
	color: white;
	padding: 3rem 2rem;
	h3 {
		width: 100%;
		text-align: center;
		max-width: 80rem;
		display: block;
		margin: 0 auto;
		font-size: 2rem; } }
