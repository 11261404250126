nav {
	padding: 1.5rem 2rem;
	border-bottom: 2px solid $rot;
	a {
		display: block;
		margin: 0;
		padding: 0; }
	img {
		width: 210px;
		display: block; } }
