.anzeige {
	border: 1px solid $hellgrau;
	display: block;
	text-decoration: none;
	&__header {
		padding:	0.7rem 1rem;
		text-align: center;
		font-family: $font;
		font-size: 1.3rem;
		color: $grau;
		border-bottom: 1px solid $hellgrau; }
	&__body {
		.button {
			display: block;
			text-decoration: none;
			background: $rot;
			color: white;
			font-size: 2rem;
			padding: 1rem 1rem;
			text-align: center;
			font-family: $value;
			font-weight: bold;
			cursor: pointer; }
		h3 {
			font-family: $univers;
			font-weight: bold;
			text-align: right;
			font-size: 1.8rem;
			line-height: 1.3;
			max-width: 50%;
			float: right; }

		&-content {
			.cta {
				padding: 0.5rem 1rem;
				font-family: $univers;
				font-size: 1.8rem; }
			&-image {
				padding: 2rem;
				width: 100%;
				height: 60vw;
				min-height: 250px;
				max-height: 500px;
				&.image_step_1 {
					background: url('../img/Q1_visual_allgemein.jpg') center no-repeat;
					background-size: 220%; } } } } }
#anzeige_multistep {
	.anzeige {
		overflow: hidden;
		&__body {
			&-content {
				&-image {
					transform: translateX(-100vw);
					transition: transform 500ms ease;
					h3 {
						transform: translateX(200vw);
						transition: transform 500ms ease; } }
				.button {
					transform: translateY(100%);
					transition: transform 500ms ease; } } } }
	&.animated {
		.anzeige {
			&__body {
				&-content {
					&-image {
						transform: translateX(0);
						h3 {
							transform: translateX(0); } }
					.button {
						transform: translateY(0); } } } } } }

